import axios from 'axios';
import MD5 from "crypto-js/md5";

const instance = axios.create(
    {
        baseURL: "https://api.muhasebe.evraka.net",
    }
);

try {
    if (!instance.defaults.headers.common['Authorization']) {
        instance.defaults.headers.common['Authorization'] = localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : null;
    }

} catch (e) {
    if (401 === e.response.status)
        window.location.href = "/user/login"
}
var hostname = window.location.hostname;

instance.defaults.params = {pkey: MD5(hostname).toString()}

export default instance
